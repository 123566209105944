

(function () {
  'use strict';

  var mapInstance;
  var markerClusterer;
  var markerClustererOptions = {
    zoomOnClick: false,
    imagePath: MARKER_CLUSTERER_IMAGE_PATH
  };

  var vueInstance = new Vue({
    el: '#map-app',
    data: data,
    mounted: function () {
      var self = this;

      this.restoreHistory();

      google
        .maps
        .event
        .addDomListener(window, 'load', function () {
          self
            .fetchMapData()
            .done(function (data) {
              self.locations = self.prepareLocations(data.property);
              self.currency = data.info.currency;
              self.area = data.info.area;
              self.center = self.getLatLngCenter(self.locations);
              self.zoom = data.info.zoom || 14;

              self.readyMap();
            })
            .fail(function () {
              alert('物件情報の取得に失敗しました');
            });
        });
    },
    watch: {
      rent_min: function (newValue, oldValue) {
        this.selected = []; // clear

        var v = parseInt(newValue);
        this.rent_max_list = this
          .rent_max_list
          .map(function (item) {
            var retValue = item;

            retValue.disabled = parseInt(item.value) <= v;

            return retValue;
          });

        this.refreshMap();
      },
      rent_max: function (newValue, oldValue) {
        this.selected = []; // clear

        var v = parseInt(newValue);
        this.rent_min_list = this
          .rent_min_list
          .map(function (item) {
            var retValue = item;

            retValue.disabled = v <= parseInt(item.value);

            return retValue;
          });

        this.refreshMap();
      },
      layouts: function (newValue, oldValue) {
        this.selected = []; // clear
        this.refreshMap();
      },
      types: function (newValue, oldValue) {
        this.selected = []; // clear
        this.refreshMap();
      },
      footprint_min: function (newValue, oldValue) {
        this.selected = []; // clear

        var v = parseInt(newValue);
        this.footprint_max_list = this
          .footprint_max_list
          .map(function (item) {
            var retValue = item;

            retValue.disabled = parseInt(item.value) <= v;

            return retValue;
          });

        this.refreshMap();
      },
      footprint_max: function (newValue, oldValue) {
        this.selected = []; // clear

        var v = parseInt(newValue);
        this.footprint_min_list = this
          .footprint_min_list
          .map(function (item) {
            var retValue = item;

            retValue.disabled = v <= parseInt(item.value);

            return retValue;
          });

        this.refreshMap();
      }
    },
    methods: {
      fetchMapData: function () {
        return $.ajax({url: MAP_DATA_API, dataType: 'json'});
      },
      getLatLngCenter: function (locations) {
        var latArray = locations.map(function (loc) {
          return parseFloat(loc.lat);
        });
        var lngArray = locations.map(function (loc) {
          return parseFloat(loc.lng);
        });
        var lat_min = Math
          .min
          .apply(null, latArray);
        var lat_max = Math
          .max
          .apply(null, latArray);
        var lng_min = Math
          .min
          .apply(null, lngArray);
        var lng_max = Math
          .max
          .apply(null, lngArray);
        var center = {
          lat: ((lat_max + lat_min) / 2.0),
          lng: ((lng_max + lng_min) / 2.0)
        };

        return center;
      },
      prepareLocations: function (locations) {
        return locations.filter(function (loc) {
          var latlng = loc
            .geo
            .split(',');

          // 緯度経度情報の無いものは無視
          var ignore = latlng[0] === undefined || latlng[1] === undefined;

          return !ignore;
        })
          .map(function (loc) {
            var retValue = loc;
            var latlng = loc
              .geo
              .split(',');

            retValue.lat = parseFloat(latlng[0]);
            retValue.lng = parseFloat(latlng[1]);

            return retValue;
          })
          .map(function (loc) {
            var retValue = loc;

            retValue.url = BASE_URL + loc.url;
            retValue.image01 = BASE_URL + loc.image01;

            return retValue;
          });
      },
      filteredLocations: function () {
        var self = this;

        // 物件絞り込み
        var filtered = this
          .locations
          .filter(function (loc) {
            // 部屋タイプ（間取り）ID
            if (self.layouts.length > 0) {
              return (self.layouts.indexOf(loc.layout_id) !== -1);
            } else {
              return true;
            }
          })
          .filter(function (loc) {
            // 家賃
            var rent = parseInt(loc.rent);
            var rent_min = self.rent_min !== ''
              ? parseInt(self.rent_min)
              : Number.MIN_VALUE;
            var rent_max = self.rent_max !== ''
              ? parseInt(self.rent_max)
              : Number.MAX_VALUE;

            return rent_min <= rent && rent <= rent_max;
          })
          .filter(function (loc) {
            // 住宅種別
            if (self.types.length > 0) {
              return (self.types.indexOf(loc.type) !== -1);
            } else {
              return true;
            }
          })
          .filter(function (loc) {
            // 広さ
            var footprint = parseInt(loc.footprint);
            var footprint_min = self.footprint_min !== ''
              ? parseInt(self.footprint_min)
              : Number.MIN_VALUE;
            var footprint_max = self.footprint_max !== ''
              ? parseInt(self.footprint_max)
              : Number.MAX_VALUE;

            return footprint_min <= footprint && footprint <= footprint_max;
          });

        console.log(filtered.length + '件');

        return filtered;
      },
      makeMarkerClusterer: function () {
        var self = this;

        var markers = this
          .filteredLocations()
          .map(function (loc) {
            var marker = new google
              .maps
              .Marker({
                position: {
                  lat: loc.lat,
                  lng: loc.lng
                },
                _extraData: loc
              });

            marker.addListener('click', function () {
              self.selected = [marker._extraData];
            });

            return marker;
          });

        markerClusterer = new MarkerClusterer(mapInstance, markers, markerClustererOptions);
        google
          .maps
          .event
          .addListener(markerClusterer, 'clusterclick', function (cluster) {
            // クラスタに含まれる物件一覧を取得
            var markers = cluster.getMarkers();
            var selected = markers.map(function (m) {
              return m._extraData;
            });
            self.selected = selected;

            // TODO: 選択中のクラスタのスタイルを変更
          });

      },
      readyMap: function () {
        mapInstance = new google
          .maps
          .Map(this.$el.querySelector('#map'), {
            zoom: this.zoom,
            center: this.center,
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.LEFT_TOP
            },
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });

        this.makeMarkerClusterer();
      },
      refreshMap: function () {
        if (markerClusterer) {
          markerClusterer.clearMarkers();
        }

        this.makeMarkerClusterer();
      },
      addHistory: function (itemId) {
        // 履歴を保存
        var data;

        if (Cookies.get('able-map') !== undefined) {
          data = Cookies.getJSON('able-map');
        } else {
          data = {
            history: []
          };
        }

        data
          .history
          .push(itemId);

        // 物件IDを配列で保存
        Cookies.set('able-map', data);
      },
      restoreHistory: function () {
        // 閲覧済履歴
        if (Cookies.get('able-map') !== undefined) {
          var history = Cookies
            .getJSON('able-map')
            .history;

          this.visited = history;
        }
      }
    }
  });

})();
